import {useEffect, useState} from "react";

const useMobile = (breakpoint: number) => {
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < breakpoint);

    useEffect(() => {
        function resizeHandler(){
            setIsMobile(window.innerWidth < breakpoint)
        }
        window.addEventListener("resize", resizeHandler);
        return () => window.removeEventListener("resize", resizeHandler);
    }, [breakpoint])
    
    return [isMobile];
}

export default useMobile;